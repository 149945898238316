<template>
	<router-view :loading="loading"></router-view>
</template>

<script>
export default {
	name: 'Pay',
	data: function() {
		return {
			title: 'Pay',
			loading: false
		};
	},
	methods: {
		async pay(campaign, registrants, body) {
			this.loading = true;

            body.registrants = registrants;

			const response = await fetch('/.netlify/functions/pay?campaign=' + campaign, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(body)
			})
				.then(data => data.json())
				.catch(error => {
					console.log(error);
				});

			const stripe = new window.Stripe(response.publishableKey);

			const { error } = await stripe.redirectToCheckout({
				sessionId: response.sessionId
			});

			if (error) {
				console.log(error);
			}
		}
	}
};
</script>
